import React, { Component, Fragment } from "react";
import { Link } from "react-router-dom";
import MyPager from "./components/MyPager";
import MyCalculation from "./utils/MyCalculation";


// content in different languages 
import English from "./lang/English";
import TraditionalChinese from "./lang/TraditionalChinese";
import Spanish from "./lang/Spanish";
import SimplifiedChinese from "./lang/SimplifiedChinese";
import Portuguese from "./lang/Portuguese";
export default class Content {
  constructor() {
    //super(props);
    console.log("Content: constructor");
  }
  static language_list = [
    {
      value: "English",
      label: "English"
    },
    {
      value: "Español",
      label: "Español"
    }
    ,
    {
      value: "TraditionalChinese",
      label: "正體中文"
    },
    {
      value: "SimplifiedChinese",
      label: "简体中文"
    },
    {
      value: "Portuguese",
      label: "Portuguese (preview)"
    }
  ];
  static all_content = {
    English: English.content,
    Español: Spanish.content,
    TraditionalChinese: TraditionalChinese.content,
    SimplifiedChinese: SimplifiedChinese.content,
    Portuguese: Portuguese.content
  };

  // not working
  /*
  static getRouteByPageAndLanguage(page, lang){
    return this["all_content"][lang].headerComponent.route_order_list.filter((routeInfo) => {
      return routeInfo.page == page;
    })[0].route;
  }
  */
}

